// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, or any plugin's
// vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file. JavaScript code in this file should be added after the last require_* statement.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//

// require("@rails/ujs").start()
require("jquery")
// require("popper")

require("@rails/activestorage").start()
require("channels")

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("website/js/plugins/popper.min.js")
require("website/js/plugins/bootstrap.min.js")
require("website/js/plugins/jquery.smoothscroll.min.js")
require("website/js/plugins/particles.min.js")
require("website/js/plugins/app.js")
require("website/js/plugins/jquery.magnific-popup.min.js")
require("website/js/plugins/isotope.pkgd.min.js")
require("website/js/plugins/imagesloaded.pkgd.min.js")
require("website/js/plugins/jquery.waypoints.min.js")
require("website/js/plugins/jquery.counterup.min.js")

require("website/js/main.js")


//   handle locale

    var locale = $('#navbarNav').data('locale');
    
    if (locale === 'en') {
        $('.nav-link-flag.en').css('filter', 'grayscale(0)')
    }else {
        $('.nav-link-flag.br').css('filter', 'grayscale(0)')
    }

    // Contact form
    $("#contact_form").submit(function(event) {
        $('.send-btn').prop("disabled", true)
        var formData = $(this).serialize();
        $.ajax({
            type: "POST",
            url: "/contact/new",
            data: formData,
            dataType: "json",
            encode: true,
        }).done(function (data) {
            let message = 'Sua mensagem foi enviada com sucesso'
            if (locale === 'en') {
                message = 'your message was sent successfully'
            }
            alert(message);
            $('.send-btn').prop("disabled", false)
            document.getElementById("contact_form").reset();
        });

        event.preventDefault();
  });


